import React, { useState, ReactNode, useCallback, useEffect } from 'react';
import TopBar from './Topbar';
import Sidebar from './Sidebar'; // Adjust the import path as necessary
import { useDispatch, useSelector } from 'react-redux';
import { useWebSocket } from '../useWebSocket';
import { setNotificationOn, setNotificationType, setNotificationTitle, setNotificationMessage, setNotificationId, setNotificationOff, addNotification, showNextNotification, markNotificationAsRead, addNotifications, markRobotNotificationAsRead } from '../../store/notification/notificationSlice';
import Notification from '../../routes/dashboard/components/Notification';
import { useRef } from 'react';
import axios from 'axios';
import { JobData, JobDetails2, RobotData3, RobotStatus2 } from '../../store/types';
import { JobEnums } from '../../utils/enums/JobEnums';
import { useSnackbar } from 'notistack'; 
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { getJobLogs } from '../../lib/api/job';
import { getRobotStatus } from '../../lib/api/robot';
import { isInPast } from '../../utils/utils';
import { dismissFireAlarm, fireAlarmWebsocketURL, showFireAlarm, useDismissAlarm, useSystemEvents } from '../../hooks/system_event';
import { setFireAlarmOn, setModalClose, triggerFire } from '../../store/fire/fireTriggerSlice';
import { SystemEventType } from '../../hooks/system_event/index.type';

interface MainLayoutProps {
  children: ReactNode;
}
interface JobHistory {
  [order_number: string]: JobDetails2[];
}

interface NotificationDetails {
  id: number;
  title: string;
  description: string;
  created_timestamp: number;
  notification_type: string;
}

interface notificationHistory {
  id: number;
  meta: string;
  notification_details: notificationDetails;
  read: boolean;
  read_timestamp: number;
  type: string;
  user: string;
}

interface notificationDetails {
  created_timestamp: number;
  description: string;
  id : number;
  notification_type: string;
  title: string;
}

let isCanPlaySound = false;

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const profile = localStorage.getItem('profile');
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);
  const audioSrc = require('../../assets/notificationSound.mp3'); // Make sure the path is correct
  const audioRef = useRef<HTMLAudioElement>(null);
  const [orderNumber , setOrderNumber] = useState<string>('');
  const [jobID, setJobID] = useState<number>(0);
  const [jobDetails, setJobDetails] = useState<JobHistory>({});
  const [jobs,setJobs] = useState<JobData>({});
  const [notificationHistory, setNotificationHistory] = useState<notificationHistory[]>([]);
  const [previousJobStatus, setPreviousJobStatus] = useState<{ [order_number: string]: string }>({});
  const [robots, setRobots] = useState<RobotData3>({});
  const [previousRobotStatus, setPreviousRobotStatus] = useState<RobotData3>({});
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const parsedProfile = profile ? JSON.parse(profile) : null;
  const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
  const notificationID = useSelector((state: any) => state.notification.notification_id);

  const handleCriticalNotification = (message: any) => {
    dispatch(setNotificationType("Job Update"));
    dispatch(addNotification(message));
    playSound();
  };

  const notificationQueue = useSelector((state: any) => state.notification.notificationsQueue);

  const handleSameOrderNumber = (orderNumber: string, exception?: string) => {
    if (!notificationQueue || notificationQueue.length === 0) {
      return
    }
    
    notificationQueue.map((item: notificationHistory) => {
      const currentOrderNumber = JSON.parse(item.meta)?.order_number

      if (currentOrderNumber && currentOrderNumber === orderNumber && (!exception || item.id.toString() !== exception)) {
        sendNotificationReadMessage({
          type: "receive_read",
          notification_id: item.id,
        });
      }
    })
  }

  // Utility function to handle non-critical notifications
  const handleNonCriticalNotification = (message: any) => {
    enqueueSnackbar(message.notification_details.description, {
      key: message.id,
      variant: 'info',
      action: (key) => (
        <Button onClick={() => handleCloseSnackbar(key, message.id)}>Dismiss</Button>
      ),
      persist: false,
      style: { backgroundColor: '#EDFAFF', color: '#000', fontFamily: "Poppins" },
      autoHideDuration: 5000, 
      onClose: (event, reason, key) => {
        // Only call handleCloseSnackbar if the reason is 'timeout' or 'clickaway'
        if (reason === 'timeout') {
          console.log("Dismissed due to timeout", key);
          handleCloseSnackbar(key, message.id);
        }
      },
    });
  };

  const handleCloseSnackbar = (key: any, message_id: number) => {
    closeSnackbar(key);
    sendNotificationReadMessage({
      type: "receive_read",
      notification_id: message_id,
    });
  }

  const handleNotification = useCallback(async(message: any) => {
    console.log("Notification received:", message);

    const isUserMessage = parsedProfile && parsedProfile?.uuid === message.user;

    if (message.notification_details.notification_type === "job_notification") {
      // Differentiate between sender and recipient notifications
      const orderNumber = JSON.parse(message.meta).order_number;
      console.log("check message id", message.id);
      if (message.read) {
        // remove notification from queue
        console.log("Marking notification as read", orderNumber, message);
        dispatch(markNotificationAsRead({ orderNo: orderNumber, notificationID: message.id }));
      } else {
        // job is not read, handle
        if (message.type === "sender_notification" && isUserMessage) {
          if (message.notification_details.title === "Robot Arrived at pickup point") {
            console.log("Critical notification sender", message.notification_details.description);
            const orderNumber = JSON.parse(message.meta)
            const isPast = isInPast(dayjs.unix(message.notification_details.created_timestamp))
            
            const isOngoingJobAndNotPast = !isPast ? await checkIsOnGoingJob(orderNumber) : false;
            if (isOngoingJobAndNotPast) {
              handleSameOrderNumber(orderNumber, message.id)
              handleCriticalNotification(message);
            }else{
              // Set as Read
              sendNotificationReadMessage({
                type: "receive_read",
                notification_id: message.id,
              });
            }
          } else {
            console.log("Non-critical notification sender ", message.notification_details.title);
            handleNonCriticalNotification(message);
          }
        } else if (message.type === "recipient_notification" && isUserMessage) {
          if (message.notification_details.title === "Robot Arrived at delivery point" ) {
            console.log("Critical notification recipient", message.notification_details.title);
            const orderNumber = JSON.parse(message.meta)
            const isPast = isInPast(dayjs.unix(message.notification_details.created_timestamp))

            const isOngoingJobAndNotPast = !isPast ? await checkIsOnGoingJob(orderNumber) : false;
            if (isOngoingJobAndNotPast) {
              handleSameOrderNumber(orderNumber, message.id)
              handleCriticalNotification(message);
            }else{
              // Set as Read
              sendNotificationReadMessage({
                type: "receive_read",
                notification_id: message.id,
              });
            }
          } else{
            console.log("Non-critical notification recipient", message.notification_details.title);
            handleNonCriticalNotification(message);
          }
        }
      }
    } else if (message.notification_details.notification_type === "robot_notification") {
      const robot_id = JSON.parse(message.meta).robot_id;

      if (message.read) {
        dispatch(markRobotNotificationAsRead({robot_id: robot_id, notificationID: message.id}));
      }else{
        dispatch(setNotificationType("Robot Update"));
        dispatch(addNotification(message));
      }
    } else {
      const orderNumber = JSON.parse(message.meta).order_number;
      if (message.read) {
        dispatch(markNotificationAsRead(orderNumber));
      } else {
        dispatch(setNotificationType("Failed"));
        dispatch(addNotification(message));
      }
    }
  }, []);

  const checkIsOnGoingJob = async (orderNumber: string) => {
    try {
      const getLogs = await getJobLogs(orderNumber)
      const logs = getLogs.data;
      // Find Log data which the status is considered as done
      const findedItemDelivered = logs.find((item: JobDetails2) => [
        // List of Done Job Status
        JobEnums.ITEM_DELIVERED,
        JobEnums.PICKED_UP_FAILED,
        JobEnums.DELIVERY_FAILED,
        JobEnums.RETURN_FAILED,
        JobEnums.CANCELLED,
        JobEnums.ARRIVED_AT_DESTINATION
      ].includes(item.job_status))

      // Consider as isOngoingJob when there is no logs or theres no item_delivered in the status
      const isOngoingJob = !logs || logs.length === 0 || !findedItemDelivered;
      return isOngoingJob
    } catch (error) {
      return false
    }
  }
  const filterIsOnGoingOrEstopJob = async (notifications: notificationHistory[]) => {
    const results: notificationHistory[] = []
    for(const data of notifications) {
      try {
        const isEstop = data.notification_details.title.toLowerCase().includes("e-stop");
        if(isEstop){
          const robotId = JSON.parse(data.meta).robot_id;

          // Check is Robot id still e-stop
          const robotStatuses: RobotStatus2[] = await getRobotStatus();
          const findRobot = robotStatuses.find((item) => item.robot_id === robotId);

          // Is still e-stop then show the notif
          if (findRobot && findRobot.robot_state === 'estop') {
            results.push(data)
          }
        }else{
          // Check Job Log based on order_number
          const orderNumber = JSON.parse(data.meta).order_number;
          const isPast = isInPast(dayjs.unix(data.notification_details.created_timestamp))
          const isOngoingJobAndNotPast = !isPast ? await checkIsOnGoingJob(orderNumber) : false; 

          // If isOnGoingJob then show as critical
          if (isOngoingJobAndNotPast) {
            results.push(data)
          }else {
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: data.id,
            });
          }
        }

      // When Something went wrong then do nothing
      } catch (error) {}
    };
    return results
  }

  const queryNotificationHistory = async (pageToLoad = 1, readStatus = false) => {
    console.log("Querying notification history", pageToLoad);
    try {
        const response = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/notification/?page=${pageToLoad}&page_size=20&read=${readStatus}`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
            },
        });

        if (response.status === 200) {

            const newNotifications = response.data.data;
            setNotificationHistory((prev) => [...prev, ...newNotifications]);
      
            // Get the existing notification IDs from the state
            const existingNotificationIds = new Set(notificationQueue.map((n: notificationHistory) => n.id));
          
            // Filter out duplicates
            const uniqueNotifications = newNotifications.filter(
              (notification: notificationHistory) => !existingNotificationIds.has(notification.id)
            );
            console.log("Unique notifications", response.data.data);

            // Keep only critical notifications
            const criticalNotifications: notificationHistory[] = await filterIsOnGoingOrEstopJob(uniqueNotifications.filter(
              (notification: notificationHistory) => 
                (notification.notification_details.title.toLowerCase() === "robot arrived at pickup point" && notification.type === "sender_notification") ||
                (notification.notification_details.title.toLowerCase() === "robot arrived at delivery point" && notification.type === "recipient_notification") ||
                notification.notification_details.notification_type === "robot_notification"
            ));
            // const criticalNotifications = criticalNotificationsFiltered)
            console.log("Critical notifications", criticalNotifications);
            const unCriticalNofications = uniqueNotifications.filter((data: notificationHistory) => {
              const findId = criticalNotifications.find((item) => data.id === item.id)
              return !findId
            })
            
            unCriticalNofications.map((notification: notificationHistory) => {
              console.log("Marking uncritical notification as read:", notification.id);
              sendNotificationReadMessage({
                type: "receive_read",
                notification_id: notification.id,
              });
            })

            const nonCriticalNotifications = uniqueNotifications.filter(
              (notification: notificationHistory) => (
                (notification.notification_details.title.toLowerCase() !== "robot arrived at pickup point" || notification.type !== "sender_notification") &&
                (notification.notification_details.title.toLowerCase() !== "robot arrived at delivery point" || notification.type !== "recipient_notification") &&
                notification.notification_details.notification_type !== "robot_notification")
            );
            
            
            console.log("Non-critical notifications", nonCriticalNotifications);
            // Mark non-critical notifications as read immediately
            nonCriticalNotifications.forEach((notification: notificationHistory) => {
              console.log("Marking non-critical notification as read:", notification.id);
              sendNotificationReadMessage({
                type: "receive_read",
                notification_id: notification.id,
              });
            });

            console.log ("existing critical notifications", criticalNotifications);
            // filter critical notifications
            // if meta data for robot is the same, take the latest notification, and read the rest, if order number is the same, take the latest notification and read the rest
            // if order number is the same, take the latest notification and read the rest
            
            // criticalNotifications.map ((notification: notificationHistory) => {
            //     console.log("Checking existing notification", notification);
            //     // const orderNumber = JSON.parse(notification.meta).order_number;
            //     // const robotId = JSON.parse(notification.meta).robot_id;
            //     if (notification.notification_details.notification_type === "robot_notification") {
            //       const robotId = JSON.parse(notification.meta).robot_id;
            //       const existingRobotNotification = notificationQueue.find((n: notificationHistory) => JSON.parse(n.meta).robot_id === robotId);
            //       console.log("Existing robot notification", existingRobotNotification);
            //     }
            //     else if (notification.notification_details.notification_type === "job_notification") {
            //       const orderNumber = JSON.parse(notification.meta).order_number;
            //       console.log("Checking existing job notification", orderNumber);
                  
            //     }
                
  
            //     // if (existingNotification) {
            //     //   console.log("Marking existing notification as read:", existingNotification.id);
            //     //   sendNotificationReadMessage({
            //     //     type: "receive_read",
            //     //     notification_id: existingNotification.id,
            //     //   });
            //     // }
        
            // });

            const notificationsByOrderNumber: { [order_number: string]: notificationHistory[] } = {};

            criticalNotifications.forEach((notification: notificationHistory) => {
              const orderNumber = JSON.parse(notification.meta).order_number;
              if (!notificationsByOrderNumber[orderNumber]) {
                notificationsByOrderNumber[orderNumber] = [];
              }
              notificationsByOrderNumber[orderNumber].push(notification);
            });
            console.log("Notifications by order number", notificationsByOrderNumber);
            // Mark older notifications as read and keep only the latest one
            const filterNewNotifs: notificationHistory[] = []
            Object.keys(notificationsByOrderNumber).forEach((orderNumber) => {
              const notifications = notificationsByOrderNumber[orderNumber];
              notifications
                .sort((a, b) => b.notification_details.created_timestamp - a.notification_details.created_timestamp)
                .forEach((notification, index) => {
                  if (index === 0) {
                    filterNewNotifs.push(notification)
                  } else {
                    sendNotificationReadMessage({
                      type: "receive_read",
                      notification_id: notification.id,
                    });
                    // dispatch(markNotificationAsRead({ orderNo: orderNumber, notificationID: notification.id }));
                  }
                });
            });
            // check notification by order number
            // if order number is the same, take the latest notification and read the rest
            console.log("Critical notifications", notificationsByOrderNumber);
            // Add all unique critical notifications at once
            dispatch(addNotifications(filterNewNotifs));
          console.log("Notification history", newNotifications);
        }
    } catch (error) {
        console.error(error);
    }
};

const [counter, setCounter] = useState(0);
const [queried, setQueried] = useState(false);
  useEffect(() => {
    if (!queried) {
      setQueried(true);
      queryNotificationHistory();
    }
  }, [queried]);



  useEffect(() => {
    console.log("Job details", jobDetails)
    console.log("Jobs", jobs)
  }, [jobs, jobDetails])

  // useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile.uuid}/`, handleNotification);
  const notificationWebSocketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile?.uuid}/`;
  const sendNotificationReadMessage = useWebSocket(notificationWebSocketUrl, handleNotification);
  const currentNotification = useSelector((state: any) => state.notification.currentNotification);  

  const handleMessage = useCallback((data: any) => {
    const job = data;
    console.log("Job data received:", job);
  
    setJobs((prevJobs) => {
      const updatedJobs = { ...prevJobs, [job.order_number]: job };
  
      // Retrieve the previous status from state
      const previousStatus = previousJobStatus[job.order_number];
      console.log("previousStatus:", previousStatus);
      console.log("job.job_status:", job.job_status);
  
      // If there's a status change or if it's the first time we're seeing this job, update previousJobStatus
      if (!previousStatus || previousStatus !== job.job_status) {
        console.log(`Updating job status from ${previousStatus} to ${job.job_status}`);
  
        // Update the previous job status
        setPreviousJobStatus((prevStatus) => ({
          ...prevStatus,
          [job.order_number]: job.job_status,
        }));
  
        // Check if the new job status means the notification should be marked as read
        if (job.job_status === JobEnums.ITEM_LOADED || job.job_status === JobEnums.ITEM_DELIVERED) {
          console.log(`Marking notification for order ${job.order_number} as read due to status change. notificationQueue:`, notificationQueue);
  
          // Find the notification ID in the queue to send read confirmation
          const notification = notificationQueue.find((notification: any) => 
            JSON.parse(notification.meta).order_number === job.order_number
          );

          console.log("Notification to mark as read:", notification);

          dispatch(markNotificationAsRead(job.order_number));
          if (currentNotification && JSON.parse(currentNotification.meta).order_number === job.order_number) {
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: currentNotification.id,
            });
          }
          if (notification) {
            const id = notification.id;
            // Send read confirmation
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: id,
            });
          }
        }
      }
  
      return updatedJobs;
    });
  }, [dispatch, previousJobStatus, notificationQueue, sendNotificationReadMessage]);


  const handleRobotStatus = useCallback((data: any) => {
    const robotId = data.robot_id;
    console.log("marking notification as read 2", currentNotification);
    setRobots((prevRobots) => {
      const updatedRobots = { ...prevRobots, 
        [robotId]: {
          robotStatus: data
        } 
      };
      const previousStatus = previousRobotStatus[robotId];
      console.log("previousRobotStatus:", previousStatus);
      console.log("data.robot_status:", data.robot_state);
      if (!previousStatus || previousStatus.robotStatus?.robot_state !== data.robot_state) {
        console.log(`Updating robot status from ${previousStatus} to ${data.robot_state}`);
        // Update the previous robot status
        setPreviousRobotStatus((prevStatus) => ({
          ...prevStatus,
          [robotId]: data,
        }));
        if (data.robot_status !== "estop" ) {
          console.log(`Marking notification for robot ${robotId} as read due to status change. notificationQueue:`, currentNotification,  notificationQueue);
          const notification = notificationQueue.find((notification: any) => 
            JSON.parse(notification.meta).robot_id === robotId
          );
          console.log("Notification to mark as read:", notification, data);
          dispatch(markRobotNotificationAsRead(robotId));
          if (currentNotification && JSON.parse(currentNotification.meta).robot_id === robotId) {
            console.log("Marking current notification as read 2:", currentNotification.id);
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: currentNotification.id,
            });
          }
          if (notification) {
            const id = notification.id;
            sendNotificationReadMessage({
              type: "receive_read",
              notification_id: id,
            });
          }
        }
      }
      return updatedRobots;
    });
  }
  , [dispatch, previousRobotStatus, notificationQueue, sendNotificationReadMessage, currentNotification]);

  const jobOverviewUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${parsedProfile?.uuid}/`;
  const robotStatus = `${process.env.REACT_APP_WEBSOCKET_URL}/robot/${parsedProfile?.organisation}/`;
  useWebSocket(jobOverviewUrl, handleMessage);
  useWebSocket(robotStatus, handleRobotStatus);
  
  // useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/notification/${parsedProfile.uuid}/`, handleNotification);
  const closeNotification = ( id: number ) => {

    // dispatch(setNotificationOff());
    console.log("closing notification", id, currentNotification);

    // After 100 milisecond, show the next notification if available
    setTimeout(() => {
      dispatch(showNextNotification());
    }, 100);
    
    sendNotificationReadMessage({
      type: "receive_read",
      notification_id: id,
    });
    //set current notification to now
  };

  
  const { data: dataFireAlarm } = useSystemEvents({ event_type: 'fire_alarm', active: true})
  const fireAlarms = dataFireAlarm?.data || []
  useEffect(() => {
    if (!fireAlarms || fireAlarms.length === 0) {
      return
    }

    showFireAlarm(dispatch, fireAlarms[0].created_time)
    
  }, [fireAlarms])

  const fireAlarmURL = fireAlarmWebsocketURL(parsedProfile)

  const handleFireAlarm = useCallback((data: SystemEventType) => {
    if (data.dismissed_time === -1) {
      showFireAlarm(dispatch, data.created_time)
      return
    }

    dismissFireAlarm(dispatch)
  }, [dispatch, previousJobStatus, notificationQueue, sendNotificationReadMessage]);

  useWebSocket(fireAlarmURL, handleFireAlarm);
  const { trigger: submitDismissAlarm } = useDismissAlarm({
    onSuccess: () => {
      dismissFireAlarm(dispatch)
    }
  })

  const onDismissAlarm = () => {
    submitDismissAlarm()
  }

  const playSound = () => {
    try {
      if (!isCanPlaySound) {
        return
      }
      const audio = new Audio(audioSrc);
      audio.play()
    } catch (error) {}
  }

  // Enable sound when user click or scroll
  const enableSound = () => {
    if (isCanPlaySound) {
      return
    }
    isCanPlaySound = true
  }

  useEffect(() => {
    document.addEventListener('click', enableSound)
    document.addEventListener('scroll', enableSound)

    return () => {
      document.removeEventListener('click', enableSound)
      document.removeEventListener('scroll', enableSound)
    }
  })
  return (
    <div>
      <TopBar toggleSidebar={toggleSidebar} handleNotificationDisplay={() => {}} notificationCount={0} handleIsOpen={toggleSidebar} />
      {/* <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} /> */}
      <main>{children}</main>

      {isNotificationOn ?
        <>
          <Notification onClose={closeNotification} onDismissAlarm={onDismissAlarm} />
          <audio ref={audioRef} src={audioSrc} autoPlay />
        </>
        : null
      }
      {/* { isNotificationOn &&
        <NoncriticalNotification/>  
    } */}
    </div>
  );
};

export default MainLayout;
