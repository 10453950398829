import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function SquareTag({ children, ...props }: { children: string | ReactNode }) {
  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center align items within the box
      width: 'auto', // Auto width based on content
      m: 0.5,
      border: `1px solid #378FFE`,
      borderRadius: "5px",
      p: "3px 10px", // Reduced padding for a more compact look
      backgroundColor: '#E8F4FF' // Light background color for distinction
    }}>
      <Typography sx={{
        fontSize: "13px",
        color: '#378FFE',
        fontWeight: 'bold',
        textAlign: 'center', // Ensures text is centered
        width: '100%' // Ensures the text takes full width of the box
      }}>
        {children}
      </Typography>
    </Box>
  )
}