import React, { useState, useEffect, useRef } from 'react';
import Fuse from "fuse.js";
import JobTable from './Components/JobTable';
import { IconButton, InputLabel, Box, Typography, Button, Grid, Dialog, DialogContent, DialogTitle, DialogActions, Alert, Select, MenuItem, FormControl, Card, Paper, OutlinedInput, TextField, InputAdornment, Menu, Divider, DialogContentText, CircularProgress, Chip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useRobotTask, useTaskReportMutation } from '../../hooks/robotTask';
import { useRobots } from '../../hooks/robot';
import { useLocationPoints } from '../../hooks/location';
import SSURate from './Components/SSURate';
import DPRate from './Components/DPRate';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { GridSearchIcon } from '@mui/x-data-grid';
import SSUDeliveryChart from './Components/SSUDeliveryChart';
import DPDeliveryChart from './Components/DPDeliveryChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FirstAttemptLiftRate from './Components/AttemptLiftRate';
import csv from '../../assets/csv.png';
import { TaskReportResponse } from '../../hooks/robotTask/index.type';
import { useNavigate } from 'react-router-dom';
import SSUAverageTime from './Components/SSUAverageTime';
import DPAverageTime from './Components/DPAverageTime';
import { COLOR } from '../../utils/colors';
import { getMaxEndDate } from '../../lib/api/function';
import html2canvas from 'html2canvas';
import { TimeNumberProps } from './Components/_type';
import jsPDF from 'jspdf';
import { DEFAULT_DATEFORMAT } from '../scheduledJob/_function/common';
import LiftSuccessRate from './Components/LiftSuccessRate';

interface columnType {
  id: string;
  label: string;
  padding?: string;
}

type TimeOptionType = {
  name: string,
  value: {
    time: number,
    unit: dayjs.ManipulateType
  }
}

const timeOptions: TimeOptionType[] = [{
  name: "Yesterday",
  value: {
    time: 1,
    unit: 'days'
  }
}, {
  name: "Today",
  value: {
    time: 0,
    unit: 'days'
  }
}, {
  name: "Past 3 days",
  value: {
    time: 3,
    unit: 'days'
  }
}, {
  name: "Past 7 days",
  value: {
    time: 7,
    unit: 'days'
  }
}, {
  name: "Past 2 weeks",
  value: {
    time: 14,
    unit: 'days'
  }
}, {
  name: "Custom",
  value: {
    time: 14, //Dummy
    unit: 'days' //Dummy
  }
}]

const defaultTime: TimeOptionType = {
  name: "Past 7 days",
  value: {
    time: 7,
    unit: 'days'
  }
}

const tasksColumn: columnType[] = [
  { id: 'job_id', label: 'Job No.', padding: "15px 20px" },
  // { id: 'job_type', label: 'Job Type', padding: "15px 20px" },
  { id: 'robot_name', label: 'Robot Name', padding: "15px 20px" },
  // { id: 'load_compartment', label: 'Compartment', padding: "15px 20px" },
  { id: 'from_location', label: 'Route' },
  { id: 'orders', label: 'Orders' },
  // { id: 'recipient_location', label: 'To' },
  { id: 'status', label: 'Status' },
  { id: 'duration', label: 'Total Duration' },
  { id: 'number_failed_deliveries', label: 'Failed Orders' },
  { id: 'created_at', label: 'Start Time' },
  { id: 'return_time_start', label: 'Delivery Completed' },
  { id: 'return_time_completed', label: 'Job Completed' },
];

const JobPage: React.FC = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("all")
  const [selectedStartLocation, setSelectedStartLocation] = useState("all")
  const [selectedEndLocation, setSelectedEndLocation] = useState("all")
  const [selectedRobotName, setSelectedRobotName] = useState("all")
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>(defaultTime.name)
  const [selectedTimeValue, setSelectedTimeValue] = useState<number>(defaultTime.value.time)
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<dayjs.ManipulateType>(defaultTime.value.unit)
  const [startGraphDate, setStartGraphDate] = useState<Dayjs | null>(null);
  const [endGraphDate, setEndGraphDate] = useState<Dayjs | null>(null);

  const [appliedFilters, setAppliedFilters] = useState<{ label: string; value: string; key: string }[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isExporting, setIsExporting] = useState(false);
  const [reportId, setReportId] = useState<number | string>(0);


  const { trigger: submitExport, isLoading: isSubmittingExport } = useTaskReportMutation({
    onSuccess: (data: TaskReportResponse) => {
      setReportId(data.report_id)
      setIsExporting(true)
    }
  })

  const [filterDialogOpen, setFilterDialogOpen] = useState(false)

  const { data: dataRobot } = useRobots()
  const robots = dataRobot?.data || []

  const { data: dataLocation } = useLocationPoints()
  const allLocations = dataLocation?.data || [];

  const { data: dataRobotTask, refetch, isLoading } = useRobotTask({
    page,
    page_size: pageSize,
    status: selectedStatus,
    start_time: startDate ? parseInt((startDate.valueOf() / 1000) + '') : '',
    end_time: endDate ? parseInt((endDate.valueOf() / 1000) + '') : '',
    start_location: selectedStartLocation,
    end_location: selectedEndLocation,
    robot_name: selectedRobotName
  })

  useEffect(() => {
    if (!selectedTime) {
      return
    }

    const findData = timeOptions.find((item) => item.name.toLowerCase() === selectedTime.toLowerCase())

    if (!findData) {
      return
    }

    setSelectedTimeValue(findData.value.time)
    setSelectedTimeUnit(findData.value.unit)
  }, [selectedTime])

  // Coment if dont want auto refetch when filter change
  useEffect(() => {
    setPage(1)
    refetch()
    validateFilter()
  }, [pageSize, selectedStatus, startDate, endDate, selectedStartLocation, selectedEndLocation, selectedRobotName])


  const validateFilter = () => {
    const newFilters = [];
    if (selectedStatus !== 'all') {
      newFilters.push({ label: `Status: ${selectedStatus}`, value: selectedStatus, key: 'statusFilter' });
    }

    if (selectedStartLocation !== 'all') {
      newFilters.push({ label: `Start Location: ${selectedStartLocation}`, value: selectedStartLocation, key: 'startLocationFilter' });
    }

    if (selectedEndLocation !== 'all') {
      newFilters.push({ label: `End Location: ${selectedEndLocation}`, value: selectedEndLocation, key: 'endLocationFilter' });
    }

    if (selectedRobotName !== 'all') {
      newFilters.push({ label: `Robot Name: ${selectedRobotName}`, value: selectedRobotName, key: 'robotFilter' });
    }

    if (startDate || endDate) {
      const formattedStartDate = startDate ? startDate.format('DD/MM/YYYY') : '';
      const formattedEndDate = endDate ? endDate.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY');
      newFilters.push({ label: `Date Range: ${formattedStartDate} - ${formattedEndDate}`, value: "", key: 'dateRange' });
    }
    setAppliedFilters(newFilters);
  }

  useEffect(() => {
    refetch()
    validateFilter()
  }, [page])

  const listData = dataRobotTask && dataRobotTask.data ? dataRobotTask.data : []
  const fuseOptions = {
    keys: ["job_id"],
    includeScore: true
  };

  const navigate = useNavigate();

  // const currentPage = dataRobotTask?.current_page || 1;
  const totalPage = dataRobotTask?.num_pages || 1
  const totalData = dataRobotTask?.count || 0

  const fuse = new Fuse(Object.values(listData), fuseOptions);

  const getFilteredData = () => {
    if (!searchTerm) return listData;

    return fuse.search(searchTerm).map(result => result.item);
  }
  const filteredData = searchTerm ? getFilteredData() : listData;

  const getJobList = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value)
    setPage(1)
  }

  const handleStartDateChange = (newStartDate: Dayjs | null) => {
    const today = dayjs();
    if (!newStartDate || !dayjs.isDayjs(newStartDate) || newStartDate.isAfter(today)) {
      setStartDate(null);
      return;
    }
    const startOfDay = dayjs(newStartDate).startOf('day');
    setStartDate(startOfDay);
  };

  const handleStartGraphDateChange = (newStartDate: Dayjs | null) => {
    const today = dayjs();
    if (!newStartDate || !dayjs.isDayjs(newStartDate) || newStartDate.isAfter(today)) {
      setStartGraphDate(null);
      return;
    }
    const startOfDay = dayjs(newStartDate).startOf('day');
    setStartGraphDate(startOfDay);
  };

  const handleEndGraphDateChange = (newEndDate: Dayjs | null) => {
    const today = dayjs();
    if (!newEndDate || !dayjs.isDayjs(newEndDate) || newEndDate.isAfter(today)) {
      setEndGraphDate(null);
      // setDuration((prev) => ({ ...prev, end_time: 0 }));
      return;
    }
    const endOfDay = dayjs(newEndDate).endOf('day');
    setEndGraphDate(endOfDay);
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {
    const today = dayjs();
    if (!newEndDate || !dayjs.isDayjs(newEndDate) || newEndDate.isAfter(today)) {
      setEndDate(null);
      // setDuration((prev) => ({ ...prev, end_time: 0 }));
      return;
    }
    const endOfDay = dayjs(newEndDate).endOf('day');
    setEndDate(endOfDay);
  };

  const handleApplyFilters = () => {
    setPage(1)
    refetch()
    setFilterDialogOpen(false)
  }

  const handleResetFilters = () => {
    setPage(1)
    setSearchTerm('')
    setSelectedStatus('all')
    setSelectedStartLocation('all')
    setSelectedEndLocation('all')
    setSelectedRobotName('all')
    setFilterDialogOpen(false)
    setFilterDialogOpen(false)
    setStartDate(null)
    setEndDate(null)
  }

  const handleChangePage = (value: number) => {
    setPage(value > totalPage ? 1 : value)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleExport = (type: string) => {
    handleCloseMenu()
    const start_time = startDate ? parseInt((startDate.valueOf() / 1000) + '') : ''
    const end_time = endDate ? parseInt((endDate.valueOf() / 1000) + '') : ''

    submitExport({
      start_time,
      end_time,
      report_type: type,
      status: selectedStatus?.toLowerCase() === "all" ? "" : selectedStatus,
      start_location: selectedStartLocation?.toLowerCase() === "all" ? "" : selectedStartLocation,
      end_location: selectedEndLocation?.toLowerCase() === "all" ? "" : selectedEndLocation,
      robot_name: selectedRobotName?.toLowerCase() === "all" ? "" : selectedRobotName,
    })
  }

  const handleExportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!isExporting || !reportId) {
      return
    }

    setTimeout(() => {
      setIsExporting(false);
      setReportId('')
      navigate(`/reports`);
    }, 8000);
  })

  const paperRef = useRef<HTMLDivElement | null>(null);


  const exportPDF = () => {
    if (!paperRef.current) {
      console.error('Paper reference is not available');
      return;
    }

    html2canvas(paperRef.current, { scale: 2, logging: true, useCORS: true })
      .then(canvas => {
        console.log('Canvas Size:', canvas.width, canvas.height);

        const imgData = canvas.toDataURL('image/jpeg', 0.3);

        // Set custom dimensions for a wider format
        const pdfWidth = 1920;  // Example width in px for a wider layout
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;  // Maintain aspect ratio

        const pdf = new jsPDF({
          orientation: 'l',  // 'l' stands for landscape
          unit: 'px',
          format: [pdfWidth, pdfHeight], // Set custom size
        });

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save(`Job Analytics ${Date.now().toString()}.pdf`);
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  const getStartEndTime = (): TimeNumberProps => {
    if (!selectedTime.toLowerCase().includes('custom')) {
      return {
        startTime: parseInt((dayjs().subtract(selectedTimeValue, selectedTimeUnit).startOf('day').valueOf() / 1000).toString()),
        endTime: parseInt((dayjs().endOf('day').valueOf() / 1000).toString()),
      }
    }

    if (!startGraphDate || !endGraphDate) {
      return {
        startTime: parseInt((dayjs().subtract(defaultTime.value.time, defaultTime.value.unit).startOf('day').valueOf() / 1000).toString()),
        endTime: parseInt((dayjs().endOf('day').valueOf() / 1000).toString()),
      }
    }

    return {
      startTime: parseInt((startGraphDate.valueOf() / 1000).toString()),
      endTime: parseInt((endGraphDate.valueOf() / 1000).toString())
    }
  }

  const handleSelectTime = (value: string) => {
    if (!value.toLowerCase().includes('custom')) {
      setStartGraphDate(null)
      setEndGraphDate(null)
    }
    setSelectedTime(value)
  }

  const handleDeleteChip = (key: string) => {
    setAppliedFilters((prevFilters) => prevFilters.filter(filter => filter.key !== key));

    // Reset the filter state values based on the deleted chip key
    if (key === 'statusFilter') {
      setSelectedStatus('all')
    }
    if (key === 'startLocationFilter') {
      setSelectedStartLocation('all')
    }
    if (key === 'endLocationFilter') {
      setSelectedEndLocation('all')
    }
    if (key === 'dateRange') {
      setStartDate(null);
      setEndDate(null);
    }

    if (key === 'robotFilter') {
      setSelectedRobotName("all");
    }

    refetch()
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <Box sx={{ maxWidth: "95%", m: "15px auto", height: "100%", }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "15px auto" }}>
          <Typography variant="h4" sx={{ color: "black" }}>Jobs</Typography>
          <Button onClick={exportPDF} variant='contained'>Export Analytics PDF</Button>
        </Box>

        <Paper ref={paperRef} sx={{ display: "flex", flexDirection: 'column', justifyContent: "right", margin: "15px auto", padding: '16px' }} >
          <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: "center", margin: "8px auto" }}>
            <Typography variant="h5" sx={{ color: "black" }}>Analytic</Typography>
            <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'right', justifyContent: "right", gap: '12px' }}>
              <Select
                size='small'
                displayEmpty
                value={selectedTime}
                onChange={(e) => handleSelectTime(e.target.value)}
                input={<OutlinedInput />}
                renderValue={(selected: string) => {
                  if (!selected) {
                    return <em>Placeholder</em>;
                  }

                  return selected
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {timeOptions.map((time: TimeOptionType) => (
                  <MenuItem
                    key={time.name}
                    value={time.name}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {time.name}
                  </MenuItem>
                ))}
              </Select>
              {
                selectedTime?.toLowerCase().includes('custom') && (
                  <Grid container spacing={2} sx={{ justifyContent: 'right' }}>
                    <Grid item xs={4} maxWidth={'300px'}>
                      <DatePicker
                        label="Start Date"
                        value={startGraphDate}
                        onChange={handleStartGraphDateChange}
                        maxDate={dayjs()}
                        format={DEFAULT_DATEFORMAT}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Typography sx={{ mt: 2, textAlign: 'center' }}>-</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        label="End Date"
                        value={endGraphDate}
                        onChange={handleEndGraphDateChange}
                        minDate={startGraphDate || undefined}
                        maxDate={dayjs()}
                        format={DEFAULT_DATEFORMAT}
                      />
                    </Grid>
                  </Grid>
                )
              }
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: 'column', alignItems: "center", margin: "15px auto" }}>
            <Grid container spacing={2} sx={{ mb: '15px' }}>
              <SSURate {...getStartEndTime()} />
              <DPRate {...getStartEndTime()} />
              <SSUAverageTime {...getStartEndTime()} />
              <DPAverageTime {...getStartEndTime()} />
              {/* <JobChart time={selectedTimeValue} timeUnit={selectedTimeUnit} /> */}
              {/* <FailedChart time={selectedTimeValue} timeUnit={selectedTimeUnit} /> */}
              <LiftSuccessRate {...getStartEndTime()} />
              <FirstAttemptLiftRate {...getStartEndTime()} />
              <SSUDeliveryChart {...getStartEndTime()} />
              <DPDeliveryChart {...getStartEndTime()} />
            </Grid>
          </Box>
        </Paper>
        <hr style={{ border: `1px solid ${COLOR.gray[200]}`, marginTop: '12px', marginBottom: '12px' }} />
        <Typography variant="h6" sx={{ mb: "10px", color: "black" }}>Job Tables</Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px auto" }}>
          <TextField
            id="search-store-name"
            variant="outlined"
            size="small"
            placeholder='Search Jobs'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: "94%",
              background: "white",
              "& .MuiOutlinedInput-root": {
                height: "40px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <GridSearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            onClick={() => setFilterDialogOpen(true)}
            sx={{ marginLeft: 2, background: "#fff", border: "1px solid #c0c0c0", boxShadow: "none", '&:hover': { background: "#f5f5f5" } }}
          >
            <FilterAltIcon sx={{ color: "#c0c0c0" }} />
          </Button>
          <Button
            disabled={isSubmittingExport}
            variant="contained"
            sx={{ marginLeft: 2 }}
            // onClick={handleExportClick}
            onClick={() => handleExport("simplified_job")}
            // endIcon={<KeyboardArrowDownIcon />}
          >
            {isSubmittingExport ? 'Exporting...' : 'Export'}
          </Button>
          {/* <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={() => handleExport("simplified_job")}>Simplified</MenuItem>
            <Divider sx={{ my: 1, width: "95%", m: "auto" }} />
            <MenuItem onClick={() => handleExport("detailed_job")}>Detailed</MenuItem>
          </Menu> */}
        </Box>
        {/* Display applied filter chips */}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
          {appliedFilters.map((filter) => (
            <Chip
              key={filter.key}
              label={
                //uppercase the first letter of the filter label
                // filter.label.charAt(0).toUpperCase() + filter.label.slice(1)
                filter.label
              }
              onDelete={() => handleDeleteChip(filter.key)}
              sx={{ backgroundColor: "#F1F7FD", color: "#378FFE", border: "1px solid #CEDBFF", textTransform: 'capitalize' }}
            />
          ))}
        </Box>
        <JobTable
          columns={tasksColumn}
          items={filteredData}
          getJobList={getJobList}
          page={page}
          totalData={totalData}
          rowsPerPage={pageSize}
          isLoading={isLoading}
          handleChangePage={(_, page) => handleChangePage(page + 1)}
          handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(parseInt(e.target.value))}
        />

        <Dialog open={filterDialogOpen} onClose={() => setFilterDialogOpen(false)} fullWidth maxWidth="sm">
          <DialogTitle>
            Filter By
            <IconButton
              aria-label="close"
              onClick={() => setFilterDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: "#a5a5a5"
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <FormControl fullWidth sx={{ mb: 2, mt: 1 }}>
              <InputLabel id="robot-id-label">Robot Name</InputLabel>
              <Select
                labelId="start-location-label"
                id="robot_id"
                name="robot_id"
                label="Robot Name"
                value={selectedRobotName}
                onChange={(e) => setSelectedRobotName(e.target.value)}
              >
                <MenuItem value="all">All Robots</MenuItem>
                {robots.map((robot, index) => (
                  <MenuItem key={index} value={robot.robot_name}>
                    {robot.robot_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                label="Status"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="all">All Status</MenuItem>
                {/* <MenuItem value={JobEnums.NOT_ASSIGNED}>Not Assigned</MenuItem>
                                <MenuItem value={JobEnums.ASSIGNED}>Assigned</MenuItem>
                                <MenuItem value={JobEnums.ASSIGNING_COMPARTMENT}>Assigning Compartment</MenuItem>
                                <MenuItem value={JobEnums.PICKING_UP}>Picking Up</MenuItem>
                                <MenuItem value={JobEnums.ARRIVED_AT_PICKUP}>Arrived At Pickup</MenuItem>
                                <MenuItem value={JobEnums.ITEM_LOADED}>Item Loaded</MenuItem>
                                <MenuItem value={JobEnums.DELIVERING}>Delivering</MenuItem>
                                <MenuItem value={JobEnums.ARRIVED_AT_DELIVERING}>Arrived At Delivering</MenuItem>
                                <MenuItem value={JobEnums.ITEM_DELIVERED}>Item Delivered</MenuItem>
                                <MenuItem value={JobEnums.RETURN_TO_SENDER}>Return To Sender</MenuItem>
                                <MenuItem value={JobEnums.PICKED_UP_FAILED}>Picked Up Failed</MenuItem>
                                <MenuItem value={JobEnums.DELIVERY_FAILED}>Delivery Failed</MenuItem>
                                <MenuItem value={JobEnums.RETURN_FAILED}>Return Failed</MenuItem>
                                <MenuItem value={JobEnums.CANCELLED}>Cancelled</MenuItem> */}
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="start-location-label">Start Location</InputLabel>
              <Select
                labelId="start-location-label"
                id="startLocation"
                name="startLocation"
                label="Start Location"
                value={selectedStartLocation}
                onChange={(e) => setSelectedStartLocation(e.target.value)}
              >
                <MenuItem value="all">All Start Locations</MenuItem>
                {allLocations.map((location, index) => (
                  <MenuItem key={index} value={location.location_name}>
                    {location.location_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="end-location-label">End Location</InputLabel>
              <Select
                labelId="end-location-label"
                id="endLocation"
                name="endLocation"
                label="End Location"
                value={selectedEndLocation}
                onChange={(e) => setSelectedEndLocation(e.target.value)}
              >
                <MenuItem value="all">All End Locations</MenuItem>
                {allLocations.map((location, index) => (
                  <MenuItem key={index} value={location.location_name}>
                    {location.location_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{ mb: 2 }}>Select Date Range:</Typography>
            <Grid container spacing={2}>
              <Grid item xs={5.5}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  maxDate={dayjs()}
                  format={DEFAULT_DATEFORMAT}
                />
              </Grid>
              <Grid item xs={0.5}>
                <Typography sx={{ mt: 2 }}>-</Typography>
              </Grid>
              <Grid item xs={5.5}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  minDate={startDate || dayjs()}
                  maxDate={getMaxEndDate(startDate, 6, 'months')}
                  format={DEFAULT_DATEFORMAT}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetFilters} sx={{ display: 'block', width: '100%' }}>
              Reset Filters
            </Button>
            <Button onClick={handleApplyFilters} sx={{ display: 'block', width: '100%' }}>
              Apply Filters
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={isExporting && !!reportId} onClose={() => setIsExporting(false)}>
          <DialogTitle>Report Generation</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", alignText: "justify" }} mb={2}>
              <DialogContentText>
                Your file is being generated. Redirecting to the download page in a few seconds...
              </DialogContentText>
              <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="flex-start" mt={4} sx={{ width: "100%", mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
                  <img src={csv} alt="csv" style={{ height: "50px" }} />
                  <Typography component="div" sx={{ ml: 3, fontSize: "16px", mt: 1.5 }}>
                    Report Reference ID: {reportId}
                  </Typography>
                </Box>
                <CircularProgress sx={{ ml: 2 }} />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </LocalizationProvider>
  )
}

export default JobPage