import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment, Chip } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import door from '../../../assets/door.png';
import elevator from '../../../assets/elevator.png';
import { generalStatus } from "../../../utils/DummyData";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { COLOR } from "../../../utils/colors";
import { getLiftStatusColor } from "../../../utils/ColourStatus";

interface GeneralDetails {
  door: StatusDetails[];
  lift: LiftStatusDetails[];
}

interface StatusDetails {
  name: string;
  status: string;
  connected: boolean;
}

interface LiftStatusDetails {
  name: string;
  tag?: string;
  current_floor?: string;
  status: string;
  connected: boolean;
  robots?: RobotLiftType[];
}

type RobotLiftType = {
  name: string,
  status: string,
  from_floor: string,
  destination_floor: string
}

interface GeneralStatusProps {
  handleStatusBoardClose?: () => void;
}

const GeneralStatus: React.FC<GeneralStatusProps> = ({ handleStatusBoardClose }) => {
  const [isShowContent, setIsShowContent] = useState(false)

  useEffect(() => {
    setIsShowContent(true)
  }, [])

  // const [tabValue, setTabValue] = useState(0);

  // const handleTabChange = (newValue: number) => {
  //     setTabValue(newValue);
  // }
  const generalInfo: GeneralDetails = generalStatus;

  // const filteredJobs = tabValue === 0 ? generalInfo.door : generalInfo.lift
  // const selectedImage = tabValue === 0 ? door : elevator;

  const getStatusLabel = (status: string, robot: RobotLiftType) => {
    switch (status) {
      case 'taking lift':
        return `taking lift from ${robot.from_floor} to ${robot.destination_floor}`
      default:
        return status
    }
  }

  const [selectedLiftFiler, setSelectedLiftFilter] = useState('Lobby D');
  const handleLiftFilter = (newValue: string) => {
    setSelectedLiftFilter(newValue);
  }
  return (
    <Stack
      sx={{
        position: "absolute",
        // top: "225px",
        top: isShowContent ? "235px" : "220px",
        opacity: isShowContent ? 1 : 0,
        right: "30px",
        width: "400px",
        bgcolor: "white",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "10px",
        height: "450px",
        transition: 'all',
        transitionDuration: '300ms'
      }}>
      <>
        <Box sx={{ height: "150px", display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m: 1 }}>
              <Typography sx={{ color: "#000", ml: 1, fontWeight: "600", fontSize: "16px", mt: 0.2 }}>
                Lift Status
              </Typography>
              <IconButton onClick={handleStatusBoardClose}>
                <CloseIcon sx={{ color: "#000", fontSize: "20px" }} />
              </IconButton>
            </Box>

            {/* <Box sx={{ display: "flex", flexDirection: "column", width: "100%",}}>
                                <Tabs 
                                    value={tabValue} 
                                    onChange={(event, newValue) => handleTabChange(newValue)}
                                    sx={{
                                        ml:2,
                                        fontSize: "8px",
                                        '.MuiTabs-indicator': {
                                            display: 'none',  
                                        },
                                        '& .MuiTab-root': {
                                            color: '#A0A0A0', 
                                            fontSize: '12px',
                                            height: '20px', 
                                            minHeight: '20px',
                                            '&.Mui-selected': {
                                                color: '#087D46', 
                                                backgroundColor: '#00E58E',
                                                borderRadius: '30px',
                                            }
                                        }
                                }}>
                                    <Tab label="Door" />
                                    <Tab label="Lift" />
                                </Tabs>
                            </Box> */}
          </Box>
        </Box>
        <Box sx={{ mt: -12 }}>
          <Tabs
            value={selectedLiftFiler}
            onChange={(_, newValue) => handleLiftFilter(newValue)}
            sx={{
              ml: 2,
              fontSize: "8px",
              '.MuiTabs-indicator': {
                display: 'none',
                height: '0px !important',
              },
              '& .MuiTab-root': {
                color: '#A0A0A0',
                fontSize: '12px',
                height: '20px !important',
                minHeight: '20px',
                '&.Mui-selected': {
                  color: '#087D46',
                  backgroundColor: '#00E58E',
                  borderRadius: '30px',
                },
              },
            }}>
            {['Lobby D'].map((level, index) => (
                <Tab key={index} value={level} label={level} />
            ))}
          </Tabs>
          <List sx={{ overflowY: "auto", overflowX: "hidden", mt: '-20px' }}>
            {generalInfo['lift'].map((job, index) => (
              <>
                <ListItem key={index} sx={{ py: 1, paddingLeft: "35px" }}>

                  <Stack sx={{ width: "60px", height: "60px", bgcolor: "#f5f5f5", color: "white", fontWeight: "600", borderRadius: "30px", display: "flex", justifyContent: "center", alignItems: "center", mr: 3 }}>
                    <img src={elevator} style={{ width: "40px", height: "40px" }} />
                  </Stack>
                  <ListItemText>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "right" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ color: "#000", fontSize: "16px", fontWeight: "700" }}>
                          {job.name}
                        </Typography>
                      </Box>
                      <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        Status:
                        <span style={{ color: getLiftStatusColor(job.status), marginLeft: "5px" }}>
                          {job.status}
                        </span>
                      </Typography>
                      <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        Connected:
                        <span style={{ color: job.connected ? "#12B76A" : "#FF0000", marginLeft: "5px" }}>
                          {job.connected ? "Online" : "Offline"}
                        </span>
                      </Typography>
                      {/* <Typography sx={{ color: "#727272", fontSize: "12px" }}>
                        {`Current Floor: ${job.current_floor}`}
                      </Typography> */}
                      {/* { job.robots && job.robots.length > 0 && (
                        <Box sx={{ padding: '8px', borderRadius: '6px', mt: '4px', border: `1px solid ${COLOR.gray[300]}`, backgroundColor: COLOR.gray[100], color: COLOR.black}}>
                          {job.robots.map((robot, index) => (
                            <Typography key={index} sx={{ color: "#727272", fontSize: "12px" }}>
                              {`${robot.name} ${getStatusLabel(robot.status, robot)}`}
                            </Typography>
                          ))}
                        </Box>
                      )} */}
                      {job.robots && job.robots.length > 0 && job.robots.map((robot, index) => (
                        <Typography key={index} sx={{ color: "#727272", fontSize: "12px" }}>
                          {`${robot.name} ${getStatusLabel(robot.status, robot)}`}
                        </Typography>
                      ))}
                    </Box>
                  </ListItemText>
                </ListItem>
                {index < generalInfo.lift.length - 1 && <Divider sx={{ width: "85%", margin: "auto", }} />}
              </>
            ))}
          </List>
        </Box>
      </>

    </Stack>
  );
}

export default GeneralStatus;