import React, { useEffect, useState } from "react";
import { Grid, Box, Button, Typography, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Card, Select, MenuItem, SelectChangeEvent, } from "@mui/material";
import swiftbotcrop from '../../../assets/swiftbotcrop.png';
import { listOfAvailableRobots } from "../../../utils/DummyData";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AccessTimeOutlined, CheckOutlined, LocalShippingOutlined } from "@mui/icons-material";
import { FormDataProps } from "../../../store/types";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { DEFAULT_DATEFORMAT } from "../../scheduledJob/_function/common";

interface OtherDetailsProps {
    timeFocused: boolean;
    handleTimeFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleTimeBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
    fieldCompleted: boolean;
    form: FormDataProps;
    handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<string>) => void;
    robots: RobotsData;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;
}


interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [order_number: string]: JobDetails2;
}

interface JobHistory {
    [order_number: string]: JobDetails2[];
}

interface Robots {
    name: string;
    description?: string;
    serialNo?: string;
    location?: string;
    status?: string;
    imageUrl?: string;
    isRobot: boolean;
}

type PickupOption = "now" | "later" | "call_nearest" | undefined;

const pickupOptions = [
  {
    name: "Pickup Now",
    description: "Available for immediate pickup",
    value: "now",
    icon: (<LocalShippingOutlined widths={18} color="inherit" />)
  },
  {
    name: "Call Robot to Location",
    description: "Call robot to pickup location immediately",
    value: "call_nearest",
    icon: (<SmartToyIcon widths={18} color="inherit" />)
  },
  {
    name: "Schedule for Later",
    description: "Choose a convenient time",
    value: "later",
    icon: (<AccessTimeOutlined widths={18} color="inherit" />)
  }
]

const OtherDetails: React.FC<OtherDetailsProps> = ({ timeFocused, handleTimeFocus, handleTimeBlur, fieldCompleted, form, handleFieldChange, robots, handleSelectChange }) => {
    // const robotList: Robots[] = listOfAvailableRobots;
    const robotList : Robots[] = [];
    robotList.push({ name: "Call Nearest Robot", description: "Priority call the fastest responding robot", isRobot: false, serialNo: "" });
    Object.keys(robots).forEach((robot_id) => {
        const robot = robots[robot_id];
        robotList.push({
            name: robot.generalInfo.robot_name ? robot.generalInfo.robot_name : "Robot",
            serialNo: robot.generalInfo.robot_mac,
            // location: robot.robotStatus?.pose.latitude + ", " + robot.robotStatus?.pose.longitude,
            status: robot.robotStatus?.robot_state === "idle" ? "Idle" : robot.robotStatus?.robot_state === "busy" ? "Busy" : robot.robotStatus?.robot_state === "charging" ? "Charging" : robot.robotStatus?.robot_state === "estop" ? "Emergency Stop" : "Offline",
            imageUrl: swiftbotcrop,
            isRobot: true
        });
    });

    useEffect(() => {
            console.log("checking form" , form);
    }, []);


    const [selectedDays, setSelectedDays] = useState<string[]>(form.recurring_days || ["do_not_repeat"]);
    const [isSelectOpen, setSelectOpen] = useState<boolean>(false);
    const [selectedPickup, setSelectedPickup] = useState<PickupOption>(form.pickupOption || "now")
    const [dateTime, setDateTime] = useState({
      date: form.date ? dayjs(form.date) : dayjs(),
      time: form.time!="Pick Up Now" ? dayjs(form.time, "HH:mm") : null,
    });

    const handleSelectChange2 = (event: SelectChangeEvent<string[]>) => {
        const { value } = event.target;
        let selectedValues = typeof value === 'string' ? value.split(',') : value;
        console.log("selectedValues", selectedValues);
        if (selectedValues.includes("do_not_repeat")) {
            setSelectedDays(["do_not_repeat"]);
        } else {
            selectedValues = selectedValues.filter((item) => item !== "do_not_repeat");
            setSelectedDays(selectedValues);
        }
    };

    const handleSelectOpen = () => {
        setSelectOpen(true);
    };

    const handleSelectClose = (event?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>) => {
        // if (event && event.preventDefault) event.preventDefault();

        setSelectOpen(false);

        let recurringType = "";
        if (selectedDays.length === 0 || selectedDays.includes("do_not_repeat")) {
            recurringType = "do_not_repeat";
        } else if (selectedDays.length === 7) {
            recurringType = "daily";
        } else if (selectedDays.length === 2 ) {
            recurringType = "biweekly";
        } else if (selectedDays.length === 1 ) {
            recurringType = "weekly";
        } else {
            recurringType = "monthly";
        }
        // will break the code

        handleFieldChange({
          preventDefault: () => {},
          target: { name: "recurring_type", value: recurringType }
        } as React.ChangeEvent<HTMLInputElement>);
        
        handleFieldChange({
          preventDefault: () => {},
          target: { name: "recurring_days", value: selectedDays.join(',') }
        } as React.ChangeEvent<HTMLInputElement>);
  };

  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(null);

  const handleDateChange = (newDate: Dayjs | null) => {
    // cannot be before today
    if (newDate && newDate.isBefore(dayjs(), 'day')) {
      return;
    }
    setDate(newDate);

    handleFieldChange({
        preventDefault: () => {},
        target: { name: "date", value: newDate?.format("YYYY-MM-DD") }
    } as React.ChangeEvent<HTMLInputElement>);
  }

  const handleTimeChange = (newTime: Dayjs | null) => {
    setTime(newTime);

    handleFieldChange({
      preventDefault: () => {},
        target: { name: "time", value: newTime?.format("HH:mm") }
    } as React.ChangeEvent<HTMLInputElement>);
  }

  const cardStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: '0.375rem',
    padding: '0.75rem',
    cursor: 'pointer',
    transition: 'all 0.2s',
    marginBottom: '0.75rem',
  }

  const selectedColor = "#3b82f6"

  const selectedCardStyle = {
    ...cardStyle,
    border:`1px solid ${selectedColor}`,
    boxShadow: `0 0 0 1px ${selectedColor}`,
  }

  const handleSelectPickup = (value: PickupOption) => {
    setSelectedPickup(value)

    if (value === 'now' || value === 'call_nearest') {
      handleTimeChange(null)
      handleDateChange(null)
      handleFieldChange({
        preventDefault: () => {},
        target: { name: "job_type", value: "pick_up_job" }
      } as React.ChangeEvent<HTMLInputElement>);
    }

    if (value === 'call_nearest') {
        handleFieldChange({
            preventDefault: () => {},
            target: { name: "recipient_location", value: form.sender_location }
        } as React.ChangeEvent<HTMLInputElement>);

        handleFieldChange({
            preventDefault: () => {},
            target: { name: "job_type", value: "adhoc_job" }
        } as React.ChangeEvent<HTMLInputElement>);
    }

    
    console.log("selectedPickup", value);
    handleFieldChange({
      preventDefault: () => {},
        target: { name: "pickupOption", value: value }
    } as React.ChangeEvent<HTMLInputElement>);
  }


  // NOTE: For now Date is optional
  const pickupValid = selectedPickup === 'now' || (time) || "call_nearest";
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        {/* <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
                <Typography sx={{ color: "#000", ml: 2, mt: 1, fontSize: "14px", fontWeight: "600" }}>
                    Scheduled Time (Optional)
                </Typography>
                {timeFocused || form.time !="Pick Up Now" && form.time !="" ? (
                    <TextField 
                        name="time"
                        type="time"
                        id="time"
                        value={form.time}
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                        onChange={handleFieldChange}
                        variant="outlined"
                        sx={{ width: "90%", ml: 2, mr: 2 }}
                    />
                ) : (
                    <TextField 
                        value="Pick up now"
                        onFocus={handleTimeFocus}
                        onBlur={handleTimeBlur}
                        variant="outlined"
                        sx={{ width: "90%", ml: 2, mr: 2 }}
                    />
                )}
            </Box> */}

        <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
          <Typography sx={{ color: "#000", ml: 2, mt: 1, fontSize: "14px", fontWeight: "600", mb: 1 }}>
            Pickup Option
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            ml: 2,
            mt: 1
          }}>
            {pickupOptions.map((item, index) => (
              <div 
                key={index}
                style={(selectedPickup === item.value as PickupOption) ? selectedCardStyle : cardStyle }
                onClick={() => handleSelectPickup(item.value as PickupOption)}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <Box sx={{ p: 1, color: selectedPickup === item.value ? selectedColor : '#000' }} >
                    { selectedPickup === item.value ? (
                      <CheckOutlined widths={18} />
                    ): (
                      <>
                       { item.icon }
                      </>
                    )}
                  </Box>
                  <Box>
                    <Typography sx={{ color: selectedPickup === item.value ? selectedColor : "#000", opacity: 0.8, fontSize: "14px", fontWeight: "600" }}>
                      {item.name}
                    </Typography>
                    <Typography 
                      sx={{ color: "#000", opacity: 0.6, fontSize: '12px'}}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </div>
              </div>
            ))}
          </Box>
        </Box>
        { selectedPickup === 'later' && (
          <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
            <Typography sx={{ color: "#000", ml: 2, mt: 1, fontSize: "14px", fontWeight: "600", mb: 1 }}>
              Scheduled Date & Time
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={5.4} sx={{ ml: 2 }}>
                <DatePicker
                  label="Date"
                  value={form.date ? dayjs(form.date) : date}
                  minDate={dayjs(new Date())}
                  onChange={handleDateChange}
                  format={DEFAULT_DATEFORMAT}
                />
              </Grid>

              <Grid item xs={5.4}>
                <TimePicker
                  label="Time"
                  // value={form.time ? dayjs(form.time).format("HH:mm") : time}
                  value={dateTime.time}
                  onChange={handleTimeChange}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" , width: "93%", mt:0.8}}>
              <Typography
                onClick ={()=>{console.log("redirect to gabriel's job scheduling page")}}
                sx={{ 
                  fontSize: "12px", 
                  color: "#378fee",
                  textDecoration: "underline", 
                  cursor: "pointer" ,
                  
                }}
              >
                Advanced Job Scheduling
              </Typography>
            </Box>

          </Box>
        )}
        {/* <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
          <Typography sx={{ color: "#000", ml: 2, mt: 2, fontSize: "14px", fontWeight: "600" }}>
            Advance Job Scheduling
          </Typography>
          <Select
            multiple
            name="recurring_type"
            value={selectedDays}
            onChange={handleSelectChange2}
            open={isSelectOpen} // control the open state
            onClose={handleSelectClose}
            onOpen={handleSelectOpen}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              width: "90%",
              ml: 2,
              mt: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#2C8DFF",
                },
              },
              whiteSpace: "normal", // Allow wrapping for the select
            }}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as string[]).map((value) => (
                  <Typography
                    key={value}
                    sx={{
                      display: 'inline-block',
                      whiteSpace: 'normal', // Allow wrapping for each selected value
                      overflow: 'visible',
                      textOverflow: 'unset',
                    }}
                  >
                    {value === "do_not_repeat" ? "None" : value.charAt(0).toUpperCase() + value.slice(1)}
                  </Typography>
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 150,
                  overflowY: 'auto',
                  whiteSpace: 'normal', // Allow wrapping within the dropdown items
                },
              },
            }}
          >
            <MenuItem value="do_not_repeat" style={{ whiteSpace: 'normal' }}>None</MenuItem>
            <MenuItem value="monday" style={{ whiteSpace: 'normal' }}>Monday</MenuItem>
            <MenuItem value="tuesday" style={{ whiteSpace: 'normal' }}>Tuesday</MenuItem>
            <MenuItem value="wednesday" style={{ whiteSpace: 'normal' }}>Wednesday</MenuItem>
            <MenuItem value="thursday" style={{ whiteSpace: 'normal' }}>Thursday</MenuItem>
            <MenuItem value="friday" style={{ whiteSpace: 'normal' }}>Friday</MenuItem>
            <MenuItem value="saturday" style={{ whiteSpace: 'normal' }}>Saturday</MenuItem>
            <MenuItem value="sunday" style={{ whiteSpace: 'normal' }}>Sunday</MenuItem>
            <Box>
              <Button
                fullWidth
                type="button"
                variant="contained"
                onClick={handleSelectClose}
                sx={{ bgcolor: "#2C8DFF", color: "white", fontWeight: "600", position: "absolute", mt: 1 }}
              >
                Confirm
              </Button>
            </Box>
          </Select>

        </Box> */}
        <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
          <Typography sx={{ color: "#000", ml: 2, mt: 2, fontSize: "14px", fontWeight: "600" }}>
            Recipient's Number
          </Typography>
          <TextField
            name="phoneNumber"
            id="phoneNumber"
            placeholder="Enter Phone Number (Optional)"
            value={form.phoneNumber}
            onChange={handleFieldChange}
            variant="outlined"
            sx={{
              width: "90%",
              ml: 2,
              mt: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#2C8DFF",
                },
              },
            }}
          />
        </Box>

        {/* <Box sx={{ display: "flex", flexDirection: "column", width: "97%", m: 1 }}>
          <Typography sx={{ color: "#000", ml: 2, mt: 2, fontSize: "14px", fontWeight: "600" }}>
            Drop-Off Wait Time
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="wait-time"
              id="waitTime"
              name="waitTime"
              value={form.waitTime}
              onChange={handleFieldChange}
              sx={{ display: "flex", flexDirection: "row", ml: 2, mt: 1, color: "#000" }}
            >
              <FormControlLabel value="10mins" control={<Radio />} label="Wait 10 mins" />
              <FormControlLabel value="indefinitely" control={<Radio />} label="Wait indefinitely" />
            </RadioGroup>
          </FormControl>
        </Box> */}

        {fieldCompleted && (
          <Box sx={{ display: "flex", flexDirection: "column", m: 1 }}>
            <Typography sx={{ color: "#000", ml: 2, mt: 1, fontSize: "14px", fontWeight: "600" }}>
              Select Robot
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup name="robot_id" id="robot_id" value={form.robot_id} onChange={handleFieldChange}>
                {robotList.map((robot, index) => (
                  <Card key={index} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", m: 1, p: 1.5, ml: 2, mr: 2, bgcolor: "#white", borderRadius: "4px", border: "1px solid #E0E0E0" }}>
                    <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", mr: 2 }}>
                      <Typography sx={{ fontWeight: "600", fontSize: "16px" }}>{robot.name}</Typography>
                      {robot.isRobot ? (
                        <>
                          <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Serial No: ${robot.serialNo}`}</Typography>
                          {/* <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Location: ${robot.location}`}</Typography> */}
                          <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{`Status: ${robot.status}`}</Typography>
                        </>
                      ) : (
                        <Typography sx={{ fontSize: "12px", color: "#8E8E8E" }}>{robot.description}</Typography>
                      )}
                    </Box>
                    {robot.imageUrl && <img src={robot.imageUrl} alt={robot.name} style={{ width: "85px" }} />}
                    <FormControlLabel
                      key={index}
                      value={robot.serialNo}
                      control={<Radio />}
                      disabled={form.recurring_type !== "do_not_repeat"}
                      label=""
                      sx={{ mr: 1 }}
                    />
                  </Card>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        <Box sx={{ display: "flex", flexDirection: "row", width: "97%", margin: "auto", mt: 4, ml: 3, mb: 2 }}>
          <Button type="submit" variant="contained" disabled={!fieldCompleted || !pickupValid} sx={{ width: "100%", bgcolor: "#2C8DFF", color: "white", fontWeight: "600", mr: 4 }}>
            Confirm Delivery
          </Button>
        </Box>
      </LocalizationProvider>

    </>
  );
};

export default OtherDetails;
