interface JobEnums {
    ORDER_CONFIRM: string;
    PICKING_UP: string;
    PICKED_UP: string;
    DELIVERING: string;
    DELIVERED: string;
    ARRIVED_AT_DELIVERING: string;
    ARRIVED_AT_PICKUP: string;
    ITEM_DELIVERED: string;
    ITEM_LOADED: string;

    RETURN_TO_SENDER: string;
    PICKED_UP_FAILED: string;
    DELIVERY_FAILED: string;
    RETURN_FAILED: string;
}

const JobEnums = {
    ORDER_CONFIRM: "order_confirm",
    PICKING_UP: "picking_up",
    DELIVERING: "delivering",
    ARRIVED_AT_DELIVERING: "arrived_at_delivering",
    ARRIVED_AT_PICKUP: "arrived_at_pickup",
    ITEM_DELIVERED: "item_delivered",
    ITEM_LOADED: "item_loaded",
    COMPLETED: "completed",

    RETURN_TO_SENDER: "return_to_sender",
    PICKED_UP_FAILED: "picked_up_failed",
    DELIVERY_FAILED: "delivery_failed",
    RETURN_FAILED: "return_failed",
    QUEUED: "queued",
    CANCELLED: "cancelled",

    NOT_ASSIGNED: "not_assigned",
    ASSIGNED: "assigned",
    ASSIGNING_COMPARTMENT: "assigning_compartment",

    DISPATCHING: "dispatching",
    ARRIVED_AT_DESTINATION: 'arrived_at_destination'
};

  export { JobEnums };
  