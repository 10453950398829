import { pieArcLabelClasses } from "@mui/x-charts"
import { COLOR } from "./colors"

export const shadow = {
  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.05)",
  '&:hover': {
    boxShadow: "1px 3px 3px 1px rgba(0, 0, 0, 0.075)",
  }
}

export const border = {
  light: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
  }
}

export const chartLabel = {
  [`& .${pieArcLabelClasses.root}`]: {
    fontWeight: 'bold',
    fill: COLOR.gray[900],
    fontSize: '14px',
    ...shadow
  },
}