import { useEffect, useRef, useState } from 'react';

// Custom hook for handling WebSocket connections for receiving messages
export function useWebSocket(url: string, onMessage: (data: any) => void, auto_reconnect = true) {
  const websocket = useRef<WebSocket | null>(null);

  const init = () => {
     // Establish the WebSocket connection
     websocket.current = new WebSocket(url);
     console.log('WebSocket connected to', url);
 
     websocket.current.onmessage = event => {
       onMessage(JSON.parse(event.data));
     };
 
     websocket.current.onclose = () => {
       console.log('WebSocket disconnected from', url)
       if (auto_reconnect) {
          console.log('WebSocket is Trying to reconnect...', url);
          init();
       }
     };
     websocket.current.onerror = error => console.error('WebSocket error:', error); 
  }

  useEffect(() => {
    init()
    // Cleanup function to close WebSocket connection when component unmounts
    return () => websocket.current?.close();
  // }, [url, onMessage]);
  }, [url]);

  // send message function
  const sendMessage = (message: any) => {
    try {
      if (!websocket.current) {
        return
      }

      websocket.current.send(JSON.stringify(message));
      console.log("Message sent to WebSocket:", message);
    } catch (error) {
      console.warn("Websocket error:", error);
    }
  };

  return sendMessage;

}
