import { useRequest } from "../../utils/api";
import { RequestConfigType } from "../../utils/api.type";
import { UserMeResponseType } from "./index.type";

const ENDPOINT_GET_ME = '/v1/rms/auth/profile/'
export const useMe = (obj = {} as RequestConfigType) => {
  return useRequest<UserMeResponseType>(ENDPOINT_GET_ME, {
    ...obj
  }, {
    enabled: obj.enabled
  })
}