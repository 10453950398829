import dayjs, { Dayjs } from "dayjs";

export const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString('en-GB').replace(/\//g, '-')} ${date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    })}`;
};

export const isInPast = (dayjsDate: Dayjs) => {
  return dayjs().isAfter(dayjsDate.add(24, 'hours'))
}

export const isValidCoordinate = (long:number, lat:number) => {
  return long !== 0 && lat !== 0
}

export const underscoreToSpace = (text: string) => {
  if (!text) {
    return ''
  }
  return text.split('_').join(' ')
}