export const COLOR = {
  neutral: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#e5e5e5',
    300: '#d4d4d4',
    400: '#a3a3a3',
    500: '#737373',
    600: '#525252',
    700: '#404040',
    800: '#262626',
    900: '#171717',
    950: '#0a0a0a'
  },
  gray: {
    50: '#f9fafb',
    100: '#f3f4f6',
    200: '#e5e7eb',
    300: '#e5e7eb',
    400: '#9ca3af',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#1f2937',
    900: '#111827',
    950: '#030712'
  },
  accent: {
    green: '#23C666',
    warning: '#FFB031',
    danger: '#FC1D1D',
    blue: '#2C8DFF',
    offline: 'rgba(160, 160, 160, 0.9)'
  },
  background: {
    green: 'rgba(45, 249, 147, 0.26)',
    warning: 'rgba(255, 175, 78, 0.26)',
    danger: 'rgba(255, 99, 78, 0.26)',
    blue: 'rgba(44, 141, 255, 0.26)',
    offline: 'rgba(150, 150, 150, 0.36)'
  },
  black: "#000000",
  white: "#ffffff",
  graph: {
    green: '#78de9c',
    blue: '#5290f3',
    red: '#e25865',
    orange: '#ecb244'
  }
}