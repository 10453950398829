import dayjs from "dayjs"

export const getMaxEndDate = (startDateValue: dayjs.Dayjs | null | undefined, number: number, unit: dayjs.ManipulateType): dayjs.Dayjs => {
  if (!startDateValue) {
    return dayjs()
  }

  const maxDate = startDateValue.add(number, unit)

  if (maxDate.isBefore(dayjs())) {
    return maxDate
  }

  return dayjs()
}

export const renderDuration = (duration: string, autoConvertToHour = true) => {
  if (!duration) {
    return ''
  }

  const durationArray = duration.split(':')

  if (durationArray.length === 2) {
    if(parseInt(durationArray[0]) >= 60 && autoConvertToHour) {
      //Minute to Hour, rest minute, and seconds
      const hour = Math.floor(parseInt(durationArray[0]) / 60)
      const minute = parseInt(durationArray[0]) % 60
      return `${hour}h ${minute}min ${durationArray[1]}s`
    }
      
    //Minute and Seconds
    return `${durationArray[0]}min ${durationArray[1]}s`
  }

  if (durationArray.length === 3) {
    //Hours, Minute and Seconds
    return `${durationArray[0]}h ${durationArray[1]}min ${durationArray[2]}s`
  }

  //seconds
  return `${durationArray[0]}s`
}