import { Dispatch, UnknownAction } from "redux"
import { useMutation, useRequest } from "../../utils/api"
import { SystemEventProps, SystemEventsResponseType } from "./index.type"
import { setFireAlarmOn, setModalClose } from "../../store/fire/fireTriggerSlice"
import { addNotification, resetNotification, setNotificationOff, setNotificationType } from "../../store/notification/notificationSlice"
import { ShortMutationConfigType } from "../../utils/api.type"


const GET_SYSTEM_EVENT = '/v1/rms/system_event/'
export const useSystemEvents = (obj = {} as SystemEventProps) => {
  return useRequest<SystemEventsResponseType>(GET_SYSTEM_EVENT, { ...obj }, { enabled: obj.enabled })
}

export const showFireAlarm = (dispatch: Dispatch<UnknownAction>, timestamp?: number) => {
  dispatch(setModalClose());
  dispatch(setNotificationType("fire"));
  dispatch(addNotification({
    id: 1,
    meta: "{}",
    notification_details: {
      created_timestamp: timestamp ? parseInt(timestamp + '') : Date.now(),
      description: "Robot Operations have been halted",
      id: 1,
      notification_type: "fire",
      title: "Fire Alarm Triggered"
    },
    read: false,
    read_timestamp: -1,
    type: "fire",
    user: "AMR"
  }));
  dispatch(setFireAlarmOn(true));
}

export const dismissFireAlarm = (dispatch: Dispatch<UnknownAction>) => {
  dispatch(setNotificationOff());
  dispatch(setFireAlarmOn(false));
  dispatch(resetNotification());
}

const STORE_DISMISS_ALARM = '/v1/rms/system_event/dismiss/'
export const useDismissAlarm = (options: ShortMutationConfigType) => {
  return useMutation<any, any>(STORE_DISMISS_ALARM, options)
}

const STORE_FIRE_ALARM = '/v1/rms/system_event/'
export const useFireAlarm = (options: ShortMutationConfigType) => {
  return useMutation<any, any>(STORE_FIRE_ALARM, options)
}

export const fireAlarmWebsocketURL = (parsedProfile?: { organisation: string }) => `${process.env.REACT_APP_WEBSOCKET_URL}/system_event/${parsedProfile?.organisation}/`;