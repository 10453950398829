import { COLOR } from "./colors";
import { JobEnums } from "./enums/JobEnums";

const getStatusColor = (status: string) => {
    const normalizedStatus = status.toLowerCase();
    switch (normalizedStatus) {
        case JobEnums.ARRIVED_AT_DESTINATION:
        case JobEnums.ITEM_DELIVERED:
        case JobEnums.COMPLETED:
            return '#00C48C';
        case JobEnums.CANCELLED:
        case JobEnums.DELIVERY_FAILED:
        case JobEnums.PICKED_UP_FAILED:
        case JobEnums.RETURN_FAILED:
        case JobEnums.RETURN_TO_SENDER:
            return '#FF0B0B';
        case JobEnums.QUEUED:
        case JobEnums.PICKING_UP:
        case JobEnums.ITEM_LOADED:
        case JobEnums.DELIVERING:
        case JobEnums.ARRIVED_AT_DELIVERING:
        case JobEnums.ARRIVED_AT_PICKUP:
        case JobEnums.ITEM_LOADED:
        case JobEnums.ITEM_DELIVERED:
        case JobEnums.ASSIGNED:
        case JobEnums.ASSIGNING_COMPARTMENT:
        case JobEnums.NOT_ASSIGNED:
            return '#378FFE';
        default:
            return 'black';
    }
};

const getLiftStatusColor = (status: string) => {
    const normalizedStatus = status.toLowerCase();
    switch (normalizedStatus) {
        case 'idle':
            return COLOR.accent.green;
        case 'amr taking lift':
            return COLOR.accent.blue;
        case 'error':
            return COLOR.accent.danger;
        default:
            return COLOR.black;
    }
}



const getRobotStatusMainColor = (status?: string) => {
  if (!status) {
    return COLOR.black;
  }

  switch (status.toLowerCase()) {
    case "idle":
    case "charging":
    case "free":
    case "online":
      return COLOR.accent.green;

    case "busy":
      return COLOR.accent.warning;

    case "offline":
      return COLOR.accent.offline;

    case 'error':
    case 'localisation_error':
    case 'hardware_error':
    case 'stuck':
    case 'estop':
      return COLOR.accent.danger;
    
    default:
      return COLOR.black;
  }
}

const getRobotStatusBackgroundColor = (status?: string) => {
  if (!status) {
    return COLOR.black;
  }

  switch (status.toLowerCase()) {
    case "idle":
    case "charging":
    case "free":
      return COLOR.background.green;

    case "busy":
      return COLOR.background.warning;

    case "offline":
      return COLOR.background.offline;

    case 'error':
    case 'localisation_error':
    case 'hardware_error':
    case 'stuck':
    case 'estop':
      return COLOR.background.danger;
    
    default:
      return 'rgba(255, 255, 255, 0.26)';
  }
}

export { getStatusColor, getRobotStatusMainColor, getRobotStatusBackgroundColor, getLiftStatusColor };
