import { useRequest } from "../../utils/api"
import { JobResponse, JobTypeProps } from "./index.type"


const defaultProps = {
  page: 1,
  page_size: 20,
  status: "all",
  start_time: 0,
  end_time: 0,
  start_location: "All",
  end_location: "All",
  robot_name: "All",
}
const GET_SYSTEM_EVENT = '/v1/rms/job_list/'
export const useJobList = (obj = defaultProps as JobTypeProps) => {
  return useRequest<JobResponse>(GET_SYSTEM_EVENT, { 
    ...obj,
    job_status: obj.status?.toLowerCase() === 'all' ? 'all_status' : obj.status,
    start_time: obj.start_time === 0 ? '' : obj.start_time,
    end_time: obj.end_time === 0 ? '' : obj.end_time,
    start_location: obj.start_location?.toLowerCase() === 'all' ? '' : obj.start_location,
    end_location: obj.end_location?.toLowerCase() === 'all' ? '' : obj.end_location,
    robot_name: obj.robot_name?.toLowerCase() === 'all' ? '' : obj.robot_name,
  }, { enabled: obj.enabled })
}