import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Box,
} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import { setTotalReportNumber, setCurrentReportPageNumber, setTotalRobotJobsPageNumber, setTotalReportPerPage  } from "../../../store/page/jobPageSlice";
import {useState, useEffect} from 'react';
import axios from 'axios';

interface ReportTableProps {
  items: ReportState[];
  columns: columnType[];
  getReportData: (pageNumber: number, rowsPerPage: number) => void;
}

interface columnType {
  id: string;
  label: string;
  padding?: string;
}

interface ReportState {
  id: number;
  requester: string;
  generating_time: number;
  completed_time: number;
  start_time: number;
  end_time: number;
  status: string;
  report_csv: string;
  tags: string;
  report_type: string;
}

const ReportTable: React.FC<ReportTableProps> = ({ items, columns, getReportData }) => {
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const totalReportNumber = useSelector((state: any) => state.jobPage.totalReportNumber);
    

    const handleChangePage = (event: null | React.MouseEvent<HTMLButtonElement>, newPage: number) => {
        setPage(newPage);
        getReportData(newPage + 1, rowsPerPage); // Backend might count pages starting from 1
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        getReportData(1, parseInt(event.target.value, 10));
      };
    
      useEffect(() => {
        getReportData(page + 1, rowsPerPage);
      }, [page, rowsPerPage]);
      
    const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const dayOptions: Intl.DateTimeFormatOptions = { weekday: 'short' };
    const dateOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
    const time = date.toLocaleTimeString('en-GB', { hour: 'numeric', minute: '2-digit', hour12: true });

    return (
      <>
        <Typography variant="body2" component="span" sx={{fontSize:"13px"}}>{time}</Typography>
        <br/>
        <Typography variant="body2" component="span" sx={{fontSize:"13px"}}>{date.toLocaleDateString('en-GB', dayOptions)}, {date.toLocaleDateString('en-GB', dateOptions)}</Typography>
      </>
    );
  };

  const splitTags = (tagString: string) => {
    if (!tagString) return []; // Handle empty tag string gracefully
    
    return tagString.split(',').map(tag => {
      const [key, value] = tag.split('=');
    
      const formattedKey = key
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize each word in the key
      
      const formattedValue = value.includes('_')
        ? value.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
        : value.charAt(0).toUpperCase() + value.slice(1);
      
      return `${formattedKey}: ${formattedValue}`;
    });
  };
  
  const checkIfStarTimePassed5mins = (start_time: number) => {
    const currentTime = new Date().getTime() / 1000;
    console.log("checking time", currentTime, start_time);
    if (currentTime - start_time > 120) {
      return true;
    }
    return false;
  }
  
  
  const renderCellContent = (columnId: string, item: ReportState) => {
    let timeFlag = false;
    timeFlag = checkIfStarTimePassed5mins(item.generating_time);
    console.log ("checking time flag", timeFlag);
    switch (columnId) {
      case 'id':
        return item.id;
      case 'requester':
        return item.requester;
      case 'generating_time':
        return formatDate(item.generating_time);
      case 'start_time':
        return item.start_time === -1 ? "" : formatDate(item.start_time);
      case 'end_time':
        return item.end_time === -1 ? "" : formatDate(item.end_time);
      case 'status':
        if (item.status === "generating") {
          if (item.report_csv === null) {
            if (timeFlag) {
              console.log("Status set to Failed due to timeFlag.");
              return "Failed";
            }
            console.log("Status set to Loading...");
            return "Loading...";
          }
        }
        return item.status.charAt(0).toUpperCase() + item.status.slice(1);
      // case 'status':
      //   return item.status === "generating" && item.report_csv === null ? "Loading..." : item.status === "generating" && item.report_csv === null && timeFlag ? "Failed" : item.status.charAt(0).toUpperCase() + item.status.slice(1);
      case 'report_csv':
        return (
          <Button
            variant="contained"
            color="primary"
            href={item.report_csv}
            target="_blank"
            disabled={!item.report_csv}
          >
            Download
          </Button>
        );
      case 'tags':
        const tags = splitTags(item.tags);
        if (tags.length === 0) {
          return (
            <Typography variant="body2" component="span" sx={{fontSize:"13px"}}>No Filter</Typography>
          );
        }
        else {
          return tags.map((tag, index) => (
            <Box sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center", // Ensures content is centered
              gap: 0.5, // Adds space between boxes
            }}>
              <Box key={index} sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", 
                  width: 'auto', 
                  m: 0.5,
                  border: `1px solid #378FFE`,
                  borderRadius: "5px",
                  p: "3px 10px", 
                  backgroundColor:  '#E8F4FF'
              }}>
                  <Typography sx={{
                      fontSize: "13px",
                      color:  '#378FFE' ,
                      fontWeight: 'bold',
                      textAlign: 'center', 
                      width: '100%' 
                  }}>
                      
                      {tag}
                  </Typography>
              </Box>
          </Box>
          ));
        }
      case 'report_type':
        return item.report_type.charAt(0).toUpperCase() + item.report_type.slice(1);
      default:
        return null;
    }
  };

  return (
    <TableContainer component={Paper} sx={{overflow:"auto ", maxHeight:"680px"}}>
      <Table sx={{overflow:"auto"}} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="center" sx={{ padding: column.padding }}>
                <Typography component="div" sx={{ fontWeight: 'bold', textAlign: 'center',  }}>
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              {columns.map((column) => (
                <TableCell key={column.id + item.id} align="center" sx={{ padding: "10px", fontSize:"13px" }}>
                  {renderCellContent(column.id, item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        
      </Table>
      <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={totalReportNumber}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ flexShrink: 0, boxShadow:"none" }} // Prevent pagination from shrinking
            />
    </TableContainer>
  );
}

export default ReportTable;
    